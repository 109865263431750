import { PropOptions } from 'vue'
import { ILink, TLink } from '~/models/base/Link'
import { IImageModel } from '~/models/base/Image'
import { IPaymentMethod } from '~/models/components/PaymentMethod'
import { IFooterLinks } from '~/models/components/FooterLinks'

export default {
  type: {
    type: String,
    validator: (v: string): boolean =>
      ['base', 'simple', 'country-restricted', 'business'].includes(v),
    default: 'base',
  },
  footerLinksGroup: {
    type: Array,
    default: () => [],
  } as PropOptions<IFooterLinks[]>,
  legalLinks: {
    type: Array,
    default: () => [],
  } as PropOptions<TLink[]>,
  paymentMethods: {
    type: Array,
    default: () => [],
  } as PropOptions<IPaymentMethod[]>,
  logo: {
    type: Object,
    default: () => ({}),
  } as PropOptions<IImageModel | Record<string, unknown>>,
  paymentMethodsPage: {
    type: Object,
    default: () => ({}),
  } as PropOptions<ILink | Record<string, unknown>>,
  isCheckout: {
    type: Boolean,
    default: false,
  },
}
