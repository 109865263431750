
import { mapGetters } from 'vuex'
import props from './props'
import { getFilteredAccountCategories } from '~/models/components/CategoryList'
import LocaleOnly, {
  ArabicLocales,
} from '~/components/utils/locale-only/index.vue'
import {
  OverlayWithNesting,
  Logo,
  PaymentMethods,
  FooterLinks,
  LegalLinks,
} from '~/components/molecules'

import { Container, Row, Column } from '~/components/grid'
import { Divider, ButtonBox, UiTitle, UiImage } from '~/components/atoms'
import { portals } from '~/enums'
import { authenticationLinks } from '~/models/components/Authentication'
import referAFriend from '~/mixins/refer-a-friend'
import signOut from '~/mixins/sign-out-iframe'

const MAXIMUM_AMOUNT_OF_PAYMENT_METHODS_SHOWN = 5
const BASE_FOOTER_TYPES = ['base', 'business']

export default {
  components: {
    Divider,
    Logo,
    PaymentMethods,
    FooterLinks,
    OverlayWithNesting,
    LegalLinks,
    Container,
    ButtonBox,
    Row,
    Column,
    UiTitle,
    UiImage,
    LocaleOnly,
  },
  mixins: [referAFriend, signOut],
  props,
  data() {
    return {
      ArabicLocales,
      BASE_FOOTER_TYPES,
    }
  },
  computed: {
    ...mapGetters('context', [
      'trustpilotLocale',
      'direction',
      'locale',
      'isContactsFeaturePreview',
      'isGlobalMarketplace',
    ]),
    ...mapGetters('contentful', ['sharedGlobalPaymentMethods']),
    ...mapGetters('user', ['isAuthenticated', 'isNativeAccount']),
    paymentMethodsOverlayName() {
      return portals.PORTAL_NAME_PAYMENT_METHODS_MODAL
    },
    unauthorizedAccountLinks() {
      return authenticationLinks.map(link => {
        return {
          ...link,
          url: `${this.$route.path}${link.url}`,
          title: this.$t(link.title),
        }
      })
    },
    showPaymentMethods() {
      return (
        this.BASE_FOOTER_TYPES.includes(this.type) ||
        (this.isCheckout && this.showTrustpilot)
      )
    },
    showTrustpilot() {
      return !['ae', 'bh', 'kw', 'om', 'qa', 'sa'].includes(
        this.locale.slice(3)
      )
    },
    categories() {
      return getFilteredAccountCategories(
        this.isContactsFeaturePreview,
        this.isNativeAccount
      ).map(category => {
        return {
          ...category,
          title: this.$t(category.title),
          description: this.$t(category.description),
          url: this.$contextPath(category.url),
        }
      })
    },
    accountLinks() {
      if (this.showRafElements) {
        return [...this.categories, this.referralProgramMenuItem]
      }
      return this.categories
    },
    limitedPaymentMethods() {
      return this.isGlobalMarketplace
        ? this.paymentMethods.slice(0, MAXIMUM_AMOUNT_OF_PAYMENT_METHODS_SHOWN)
        : this.paymentMethods
    },
    accountBlockLinks() {
      return {
        title: this.$t('account.overview.my_account'),
        groupLinks: this.isAuthenticated
          ? this.accountLinks
          : this.unauthorizedAccountLinks,
      }
    },
    gridColumns() {
      return this.footerGroup.length + 1
    },
    footerGroup() {
      if (!this.isRafInvitationPage) {
        return [this.accountBlockLinks, ...this.footerLinksGroup]
      }
      return this.footerLinksGroup
    },
    trustpilotIdentifier() {
      return `footer-${this.direction}`
    },
    entityName() {
      const year = new Date().getFullYear()
      return this.$t('organisms.footer.entity_name', { year })
    },
  },
  methods: {
    showPaymentMethodsModal() {
      this.$store.dispatch('ui/showNestingOverlay', {
        name: this.paymentMethodsOverlayName,
      })
    },
  },
}
