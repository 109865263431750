import { AUTH_MODAL_TYPES } from '~/utils/constants/auth'

export interface IAuthenticationLink {
  title: string
  buttonType: string
  url: string
  slug: string
  customBehavior: boolean
}

export const authenticationLinks: IAuthenticationLink[] = [
  {
    title: 'authentication.navigation.sign_in_label',
    buttonType: 'secondary-dark',
    url: `?auth=${AUTH_MODAL_TYPES.LOGIN}`,
    slug: 'sign-in',
    customBehavior: false,
  },
  {
    title: 'authentication.navigation.register_label',
    buttonType: 'primary',
    url: `?auth=${AUTH_MODAL_TYPES.REGISTER}`,
    slug: 'register',
    customBehavior: false,
  },
]
